<template>
  <div class="content-layout">
    <div class="select-container">
      <a-row>
        <a-col :span="2">
          <a-button v-if="$hasPermissions(['rcs:cpu_feature_relation:create'])" type="primary" @click="addCpuFunction"
            >新增</a-button
          >
        </a-col>
        <a-col :span="22" style="display: flex; justify-content: flex-end">
          <a-form :model="queryForm" layout="inline">
            <a-form-item label="中控型号">
              <a-select show-search class="select" v-model="queryForm.model" @change="modelChange" allowClear>
                <a-select-option
                  v-for="model in modelList"
                  :key="model.model"
                  :value="model.model"
                  :title="model.model"
                  >{{ model.model }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="中控硬件版本">
              <a-select show-search class="select" v-model="queryForm.hardVersion" allowClear @change="doSearch">
                <a-select-option
                  v-for="hardVersion in hardVersionList"
                  :key="hardVersion.hardVersion"
                  :value="hardVersion.hardVersion"
                  :title="hardVersion.hardVersion"
                  >{{ hardVersion.hardVersion }}</a-select-option
                ></a-select
              >
            </a-form-item>
            <a-form-item label="中控软件版本">
              <a-select show-search class="select" v-model="queryForm.softVersion" allowClear @change="doSearch">
                <a-select-option
                  v-for="softVersion in softVersionList"
                  :key="softVersion.softVersion"
                  :value="softVersion.softVersion"
                  :title="softVersion.softVersion"
                  >{{ softVersion.softVersion }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="中控功能">
              <a-select show-search class="select" v-model="queryForm.feature" allowClear @change="doSearch">
                <a-select-option
                  v-for="feature in functionList"
                  :key="feature.feature"
                  :value="feature.feature"
                  :title="feature.feature"
                  >{{ feature.feature }}</a-select-option
                ></a-select
              >
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>
    <div class="table-container">
      <a-table
          class="list-table"
        :columns="this.$constRepository.cpuRelation.columns"
        :data-source="tableData"
        :pagination="false"
        rowKey="id"
        @change="tableChange"
      >
        <template slot="lastModificationTime" slot-scope="scope">
          {{ scope | parseTime | orNA }}
        </template>
        <template slot="features" slot-scope="scope">
          <span v-if="scope">
            {{ scope.join(',') }}
          </span>
          <span v-else>-</span>
        </template>
        <template slot="action" slot-scope="scope">
          <div>
            <a-button
              v-if="$hasPermissions(['rcs:cpu_feature_relation:update'])"
              class="green-btn"
              ghost
              @click="editHandler(scope)"
              >编辑</a-button
            >
            <a-divider type="vertical" />
            <a-button
              v-if="$hasPermissions(['rcs:cpu_feature_relation:get'])"
              class="green-btn"
              ghost
              @click="detailHandler(scope)"
              >详情</a-button
            >
            <a-divider type="vertical" />
            <a-popconfirm okText="是" cancelText="否" title="是否删除中控功能关联?" @confirm="handleDelete(scope)">
              <a-button v-if="$hasPermissions(['rcs:cpu_feature_relation:delete'])" class="red-btn" ghost
                >删除</a-button
              >
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page + 1"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </div>
    <cpu-relation-add-or-edit-modal
      v-if="addOrEditVisible"
      :visible.sync="addOrEditVisible"
      :item="currentItem"
      @updateView="getList"
    />
    <cpu-relation-detail-modal v-if="detailVisible" :visible.sync="detailVisible" :item="currentItem" />
  </div>
</template>
<script>
import lodash from 'lodash';
import CpuRelationAddOrEditModal from './components/CpuRelationAddOrEditModal';
import CpuRelationDetailModal from './components/CpuRelationDetailModal';

export default {
  name: 'CpuRelationManagement',
  components: { CpuRelationAddOrEditModal, CpuRelationDetailModal },
  data() {
    return {
      addOrEditVisible: false,
      detailVisible: false,
      tableData: [],
      pageInfo: {
        page: 0,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      currentItem: undefined,
      queryForm: {},
      modelList: [],
      softVersionList: [],
      hardVersionList: [],
      functionList: [],
      isWidth: false,
      order: '',
      orderField: '',
    };
  },
  methods: {
    /** 新增中控功能 */
    addCpuFunction() {
      this.currentItem = undefined;
      this.addOrEditVisible = true;
    },
    /** 编辑 */
    editHandler(scope) {
      this.currentItem = lodash.cloneDeep(scope);
      this.addOrEditVisible = true;
    },
    /** 删除 */
    async handleDelete(scope) {
      await this.$apiManager.cpuRelation.delCpuRelation(scope.id);
      this.$message.success('删除成功');
      this.getList();
    },
    /** 详情 */
    detailHandler(scope) {
      this.currentItem = scope;
      this.detailVisible = true;
    },
    async listCpuRelation() {
      let payload = {
        model: this.queryForm.model || '-',
        feature: this.queryForm.feature,
        hardVersion: this.queryForm.hardVersion,
        softVersion: this.queryForm.softVersion,
        page: this.pageInfo.page,
        size: this.pageInfo.size,
      };
      if (this.order && this.orderField) {
        payload = { ...payload, orderBy: `${this.orderField} ${this.order}` };
      }
      const res = await this.$apiManager.cpuRelation.getCpuRelationList(payload);
      this.tableData = res.data.elements;
      this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
    },
    /** 获取中控关联列表 */
    async getList() {
      await this.listCpuRelation();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 0;
        await this.listCpuRelation();
      }
    },
    /** 型号选择发生改变,根据型号获取软硬件版本 */
    modelChange(model) {
      this.$set(this.queryForm, 'hardVersion', undefined);
      this.$set(this.queryForm, 'softVersion', undefined);
      const params = { model, unpaged: true };
      Promise.all([
        this.$apiManager.cpuHardware.getCpuHardwareList(params),
        this.$apiManager.cpuSoftware.getCpuSoftwareList(params),
      ]).then((res) => {
        this.hardVersionList = res[0].data.elements;
        this.softVersionList = res[1].data.elements;
      });
      this.doSearch();
    },
    /** 版本或功能发生改变时，进行搜索 */
    async doSearch() {
      this.pageInfo.page = 0;
      this.getList();
    },
    /** 获取型号与功能列表 */
    getModelAndFunctionList() {
      const params = { unpaged: true };
      Promise.all([
        this.$apiManager.cpuModel.getCpuModelList(params),
        this.$apiManager.cpuFunction.getCpuFunctionList(params),
      ]).then((res) => {
        this.modelList = res[0].data.elements;
        this.functionList = res[1].data.elements;
      });
    },

    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 0;
      this.$set(this.pageInfo, 'size', pageSize);
      this.getList();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber - 1);
      this.getList();
    },
    tableChange(p, f, s) {
      if (s.order) {
        this.order = s.order === 'descend' ? 'desc' : 'asc';
        this.orderField = s.field;
      } else {
        this.order = '';
        this.orderField = '';
      }
      this.getList();
    },
  },
  mounted() {
    this.getList();
    this.getModelAndFunctionList();
  },
};
</script>
<style lang="less" scoped>
.content-layout {
  padding:16px 24px;
  .select-container {
    @media screen and (min-width: 1441px) {
      .select {
        width: 10rem;
      }
    }
    @media screen and (max-width: 1440px) {
      .select {
        width: 6rem;
      }
    }
  }
  .table-container {
    .list-table{
      margin-top: 1%;
    }
    .green-btn {
      color: green;
      border: none;
    }

    .red-btn {
      border: none;
      color: red;
    }
    .pagination {
      margin-top: 3%;
    }
  }
}
</style>
